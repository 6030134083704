import { Typography } from "../Typography/Typography.jsx";
import { Box } from "../Box/Box.jsx";
import { Spacer } from "../Spacer/Spacer.jsx";

import "./Block1.css";

export const Block1 = ({ data, button }) => {
  const block1 = data;
  return (
    <Box justifyContent="center" className="block1_1">
      <Box justifyContent="space-between" className="block1_2">
        <Box flexDirection="column" className="block1_3">
          <Typography
            component="h1"
            variant="h1"
            color="main_1"
            className="block1_title"
          >
            {block1.title}
          </Typography>
          <Spacer size={8} />
          <Typography
            component="h3"
            variant="h3"
            color="main_1"
            className="block1_subtitle"
          >
            {block1.subtitle}
          </Typography>
          <Spacer size={0} tabSize={27} mobSize={25} />
        </Box>
        <a
          href={button.url}
          target="_blank"
          className="block1_header_button"
          rel="noreferrer"
        >
          <Typography component="span" variant="textXS" color="white_1">
            {button.title1}
          </Typography>
        </a>
        <Spacer size={0} mobSize={40} />
        <Box flexDirection="column">
          <Box className="block1_4">
            <Typography
              component="p"
              variant="textS"
              color="black_09"
              className="block1_text"
            >
              {block1.text}
            </Typography>
          </Box>
          <Spacer size={14} />
          <Box theme="signature_child_block1">
            <Box theme="image_child_block1">
              <img alt={block1.signature.name} src={block1.signature.image} />
            </Box>
            <Box flexDirection="column" justifyContent="center">
              <Typography component="p" variant="textS" color="black_1">
                {block1.signature.name}
              </Typography>
              <Typography component="p" variant="textXXS" color="black_06">
                {block1.signature.post}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
