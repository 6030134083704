import { useEffect, useState, useRef, useMemo } from "react";

import { Typography } from "./components/Typography/Typography.jsx";
import { Box } from "./components/Box/Box.jsx";
import { Spacer } from "./components/Spacer/Spacer.jsx";

import { Block1 } from "./components/Block1/Block1.jsx";
import { Block3 } from "./components/Block3/Block3.jsx";
import { Block4 } from "./components/Block4/Block4.jsx";
import { Block5 } from "./components/Block5/Block5.jsx";
import { Block6 } from "./components/Block6/Block6.jsx";

import logo from "./images/logo.svg";
import "./App.css";
import { data } from "./data.js";

const WIDTH_WRAPPER = 1087;

const App = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const myRef = useRef(null);

  const handleResize = () => {
    // Обновляем состояние при изменении ширины окна
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Подписываемся на событие resize
    window.addEventListener("resize", handleResize);

    // Это функция для очистки, она будет вызвана перед удалением компонента
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentPosition = window.scrollY;
      const isScrollingDown = currentPosition > lastScrollY;
      const scrollDifference = Math.abs(currentPosition - lastScrollY);

      if (currentPosition >= 250 && currentPosition <= 1000) {
        const scrollAmount = isScrollingDown
          ? scrollDifference
          : -scrollDifference;
        myRef.current.scrollLeft += scrollAmount * 2;
      }

      lastScrollY = currentPosition; // Обновляем lastScrollY для следующего вызова
    };

    if (windowWidth > 1024) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [windowWidth]);

  const offset = useMemo(() => {
    return (windowWidth - WIDTH_WRAPPER) / 2;
  }, [windowWidth]);

  const { block1, block2, block3, block4, block5, block6 } = data;

  return (
    <div>
      <Box flexDirection="column" theme="withBackgroundColor">
        {/* HEADER */}
        <div
          style={{
            backgroundColor: isScrolled ? "white" : "transparent",
            paddingTop: isScrolled ? "8px" : "36px",
            paddingBottom: isScrolled ? "8px" : "36px",
          }}
          className="header"
        >
          <a href="/" rel="noreferrer">
            <img alt="Maracas AI" src={logo} />
          </a>
          <a
            href={block6.button.url}
            target="_blank"
            className="header_button"
            rel="noreferrer"
          >
            <Typography component="span" variant="textXS" color="white_1">
              {block6.button.title1}
            </Typography>
          </a>
        </div>

        {/* BLOCK 1 */}
        <Spacer size={150} tabSize={85} mobSize={65} />
        <Block1 data={block1} button={block6.button} />
        <Spacer size={82.5} tabSize={60} mobSize={40} />

        {/* BLOCK 2 */}
        <Box justifyContent="center" className="block2_1">
          <Box flexDirection="column" className="block2_2">
            <Typography component="h2" variant="h2" color="main_05">
              {block2.title}
            </Typography>
            <Spacer size={35} tabSize={30} mobSize={16} />
          </Box>
        </Box>
        {offset && (
          <div
            ref={myRef}
            className="scroll"
            style={{ paddingLeft: windowWidth > 1024 ? `${offset}px` : '20px'}}
          >
            <Box theme="block2" className="block2_4">
              {block2.items.map((item, i) => {
                return (
                  <Box key={i} flexDirection="column" theme="child_block2">
                    <Typography component="h4" variant="h4" color="gray10_1" className="block2_title">
                      {item.title}
                    </Typography>
                    <Spacer size={5} />
                    <Typography component="p" variant="textXS" color="gray20_1" className="block2_subtitle">
                      {item.text}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <div className="add-child" style={{ width: windowWidth > 1024 ? `${offset}px` : '20px' }} />
          </div>
        )}
        <Spacer size={75} tabSize={50} mobSize={30} />

        {/* BLOCK 3 */}
        <Block3 data={block3} />
        <Spacer size={75} tabSize={60} mobSize={40} />

        {/* BLOCK 4 */}
        <Block4 data={block4} />
        <Spacer size={75} tabSize={45} mobSize={40} />
      </Box>
      <Spacer size={75} mobSize={40} />

      {/* BLOCK 5 */}
      <Block5 data={block5} />
      <Spacer size={95} tabSize={61} mobSize={40} />

      {/* BLOCK 6 */}
      <Block6 data={block6} />
    </div>
  );
};

export default App;
