import { Typography } from "../Typography/Typography.jsx";
import { Box } from "../Box/Box.jsx";
import { Spacer } from "../Spacer/Spacer.jsx";

import "./Block4.css";

export const Block4 = ({ data }) => {
  const block4 = data;
  return (
    <Box justifyContent="center" className="block4_1">
      <Box flexDirection="column" className="block4_2">
        <Typography component="h2" variant="h2" color="main_05">
          {block4.title}
        </Typography>
        <Spacer size={40} tabSize={30} mobSize={20} />
        <Box flexDirection="column" className="block4_3">
          {block4.items.map((item, i) => {
            return (
              <Box
                key={i}
                flex="1"
                justifyContent="space-between"
                theme="parent_block4"
                className="block4_4"
              >
                <Box flexDirection="column" className="block4_5">
                  <Typography component="h4" variant="h4" color="main_1">
                    {item.title}
                  </Typography>
                  <Spacer size={5} />
                  <Typography component="p" variant="textXS" color="gray20_1">
                    {item.text}
                  </Typography>
                </Box>
                <Box
                  flexDirection="column"
                  theme="child_block4"
                  className="block4_6"
                >
                  <Box
                    alignItems="center"
                    theme="child_block4_header"
                    className="block4_7"
                  >
                    <Typography component="h6" variant="h6" color="gray20_1">
                      {item.block.title}
                    </Typography>
                  </Box>
                  <img
                    alt={item.block.title}
                    src={item.block.image}
                    className="block4_image"
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
