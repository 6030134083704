import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Typography.css';

export const Typography = ({ component, variant, color, children, className, ...props }) => {
  const Component = component;
  return <Component {...props} className={cn('tg', `variant_${variant}`, `color_${color}`, className)}>{children}</Component>;
};

Typography.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
};
