import { Typography } from "../Typography/Typography.jsx";
import { Box } from "../Box/Box.jsx";
import { Spacer } from "../Spacer/Spacer.jsx";

import "./Block3.css";

export const Block3 = ({ data }) => {
  const block3 = data;
  return (
    <Box justifyContent="center" className="block3_1">
      <Box flexDirection="column" className="block3_2">
        <Typography component="h2" variant="h2" color="main_05">
          {block3.title}
        </Typography>
        <Spacer size={40} tabSize={30} mobSize={20} />
        <Box flexWrap="wrap" className="block3_3">
          {block3.items.map((item, i) => {
            return (
              <Box key={i} flexDirection="column" className="block3_4">
                <img
                  aria-hidden
                  alt={item.title}
                  src={item.icon}
                  className="block3_icon"
                />
                <Spacer size={10} />
                <Typography component="h4" variant="h4" color="main_1">
                  {item.title}
                </Typography>
                <Spacer size={5} />
                <Typography component="p" variant="textXS" color="gray20_1">
                  {item.text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
