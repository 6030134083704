import { Typography } from "../Typography/Typography.jsx";
import { Box } from "../Box/Box.jsx";
import { Spacer } from "../Spacer/Spacer.jsx";

import "./Block6.css";

export const Block6 = ({ data }) => {
  const block6 = data;
  return (
    <Box alignItems="center" flexDirection="column" theme="block6" className="block6_1">
      <Spacer size={75} mobSize={40} />
      <Box
        alignItems="center"
        flexDirection="column"
        theme="child_block6"
        className="block6_2"
      >
        <Spacer size={25} mobSize={20} />
        <Typography component="p" variant="textXS" color="black_1">
          {block6.title}
        </Typography>
        <Spacer size={2.5} mobSize={8} />
        <Typography component="p" variant="textMBold" color="main_1">
          {block6.text}
        </Typography>
        <Spacer size={12} mobSize={20} />
        <a
          href={block6.button.url}
          target="_blank"
          className="block6_button"
          rel="noreferrer"
        >
          <Typography component="span" variant="textM" color="gray10_1" className="block6_2_button_label">
            {block6.button.title2}
          </Typography>
        </a>
        <Spacer size={36} mobSize={20} />
      </Box>
      <Spacer size={75} mobSize={40} />
    </Box>
  );
};
