// Images for Block1 (Customized\nGenerative AI)
import photo_Ivan from './images/Ivan.jpg';

// Icons for Block3 (Core Services)
import block3_icon1 from './icons/block3_icon1.svg';
import block3_icon2 from './icons/block3_icon2.svg';
import block3_icon3 from './icons/block3_icon3.svg';
import block3_icon4 from './icons/block3_icon4.svg';
import block3_icon5 from './icons/block3_icon5.svg';
import block3_icon6 from './icons/block3_icon6.svg';

// Images for Block4 (Industry-Specific AI Solutions)
import block4_image1 from './images/block4_image1.webp';
import block4_image2 from './images/block4_image2.webp';
import block4_image3 from './images/block4_image3.webp';
import block4_image4 from './images/block4_image4.webp';
import block4_image5 from './images/block4_image5.webp';
import block4_image6 from './images/block4_image6.webp';

// Images for Block5 (Digital Retail Transformation)
import block5_image1 from './images/block5_image1.webp';
import block5_image2 from './images/block5_image2.webp';
import block5_image3 from './images/block5_image3.webp';

export const data = {
    block1: {
        title: 'Customized\nGenerative AI',
        subtitle: 'For the Modern Enterprise' ,
        text: 'Ivan brings a storied 12-year history in AI,\nunderscored by his role in a global top-10\nlanguage model initiative. He’s the brains\nbehind Maracas.ai, leveraging a robust\nnetwork of skilled developers to deliver\nnuanced AI solutions.',
        signature: {
            image: photo_Ivan,
            name: 'Ivan',
            post: 'Your AI Strategist'
        }
    },
    block2: {
        title: 'Our Methodology',
        items: [
            {
                title: 'Comprehensive Evaluation',
                text: 'We dive deep into your company’s\necosystem to discover AI opportunities.'
            },
            {
                title: 'Bespoke Strategy Formation',
                text: 'Crafting a unique AI plan that resonates\nwith your business trajectory.'
            },
            {
                title: 'Seamless AI Integration',
                text: 'Fitting new AI gears into the machinery\nof your existing systems.'
            },
            {
                title: 'Continual Enhancement',
                text: 'Ensuring AI tools evolve with your business,\nmaintaining their cutting edge.'
            }
        ]
    },
    block3: {
        title: 'Core Services',
        items: [
            {
                icon: block3_icon1,
                title: 'Strategic AI Roadmapping',
                text: 'We chart a future-forward AI course that aligns\nwith your business ambitions, ensuring\nsustainable growth and technological relevance.'
            },
            {
                icon: block3_icon2,
                title: 'Custom AI Solutions Development',
                text: 'Our AI solutions aren’t just cutting-edge; they’re cut-to-fit,\ntailor-made to address your specific business challenges.',
            },
            {
                icon: block3_icon3,
                title: 'AI Integration Services',
                text: 'We weave AI into the fabric of your existing\ninfrastructure, enhancing synergy across your IT landscape.',
            },
            {
                icon: block3_icon4,
                title: 'AI Optimization',
                text: 'Tuning the engines of your AI apparatus, we amplify their\nperformance and maximize operational efficiencies.',
            },
            {
                icon: block3_icon5,
                title: 'Data Science and Analytics',
                text: 'We translate data into narratives, uncovering\npatterns and predictions through advanced\nanalytics and insightful visualizations.',
            },
            {
                icon: block3_icon6,
                title: 'AI Ethics and Compliance Consulting',
                text: 'Our ethical compass and legal acumen ensure your AI\ndeployment isn’t just effective—it’s also responsible and\ncompliant.',
            },
        ]
    },
    block4: {
        title: 'Industry-Specific AI Solutions',
        items: [
            {
                title: 'Enhanced Customer Service',
                text: 'AI-driven systems that provide\npersonalized customer interactions\nand support.',
                block: {
                    title: 'Maracas AI support',
                    image: block4_image1
                }
            },
            {
                title: 'Advanced Market Analytics',
                text: 'Harnessing AI to gain a deeper\nunderstanding of market trends\nand customer behavior.',
                block: {
                    title: 'Maracas Market Trends Dashboard',
                    image: block4_image2
                }
            },
            {
                title: 'Content Generation and SEO',
                text: 'Utilizing AI to create compelling\ncontent and improve search engine\nvisibility.',
                block: {
                    title: 'Content for “best maracas for samba” “eco-friendly percussion instruments”',
                    image: block4_image3
                }
            },
            {
                title: 'Precision Inventory Management',
                text: 'AI solutions for optimizing stock\nlevels and predicting demand more\naccurately.',
                block: {
                    title: 'Inventory',
                    image: block4_image4
                }
            },
            {
                title: 'Smart Recruitment Tools',
                text: 'AI-powered assistance to\nstreamline the talent acquisition\nprocess.',
                block: {
                    title: 'Recruitment',
                    image: block4_image5
                }
            },
            {
                title: 'Proactive Sales Enablement',
                text: 'Leveraging AI to empower your\nsales team with insights and\nautomated assistance.',
                block: {
                    title: 'Maracas Sales Insight App',
                    image: block4_image6
                }
            }
        ]
    },
    block5: {
        title: 'Digital Retail Transformation',
        text: 'A trailblazing e-retailer faced the challenge\nof scaling customer service and supply chain\noperations efficiently.',
        items: [
            {
                image: block5_image1,
                title: 'Challenge',
                text: 'They required a nuanced approach to enhance\ncustomer engagement and streamline logistical\noperations without escalating costs.',
            },
            {
                image: block5_image2,
                title: 'Solution',
                text: 'Maracas.ai implemented a trio of AI-powered\nsolutions: an intuitive support chatbot, a smart\ninventory forecasting system, and a dynamic\ncontent generation tool for SEO optimization.',
            },
            {
                image: block5_image3,
                title: 'Outcome',
                text: 'The retailer saw a 40% cut in operational expenses\nand a 30% uplift in supply chain efficacy, along with\na measurable jump in online customer interaction.',
            }
        ],
        quote1: `As we navigated the complexities of scaling our e-commerce platform,\nMaracas.ai emerged as a beacon of innovation, guiding us through\na transformative journey. The AI solutions tailored by Ivan and his team\ndidn't just resolve our challenges — they redefined our operations.`,
        quote2: `We've seen a remarkable optimization in customer service efficiency\nand a new level of precision in our supply chain management. Thanks\nto Maracas.ai, we are not just thriving; we are setting new benchmarks\nin the digital retail space.`
    },
    block6: {
        title: 'Empower Your Business with AI',
        text: 'Chart your path to AI excellence\nwith Ivan’s expertise',
        button: {
            title1: 'Book Consultation',
            title2: 'Book a Strategic Consultation with Ivan',
            url: 'https://calendly.com/maracas-ai/30-minute-consultation',
        }
    }
};