import { Typography } from "../Typography/Typography.jsx";
import { Box } from "../Box/Box.jsx";
import { Spacer } from "../Spacer/Spacer.jsx";

import "./Block5.css";

export const Block5 = ({ data }) => {
  const block5 = data;
  return (
    <Box justifyContent="center" className="block5_1">
      <Box flexDirection="column" className="block5_2">
        <Typography component="h2" variant="h2" color="main_05">
          {block5.title}
        </Typography>
        <Spacer size={25} />
        <Box flexDirection="column" theme="block5" className="block5_3">
          <Box alignSelf="flex-end" className="block5_4">
            <Typography component="p" variant="textLBold" color="black_1" className="block5_4_text">
              {block5.text}
            </Typography>
          </Box>
          {block5.items.map((item, i) => {
            return (
              <Box key={i} flex="1" theme="child_block5" className="block5_5">
                <Box theme="image_child_block5" className="block5_6">
                  <img
                    alt={item.title}
                    src={item.image}
                    className="block5_image"
                  />
                </Box>
                <Box alignSelf="center" flexDirection="column" className="block5_7">
                  <Typography component="h5" variant="h5" color="black_1">
                    {item.title}
                  </Typography>
                  <Spacer size={8} />
                  <Typography component="p" variant="textM" color="black_1" className="block5_7_text">
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Spacer size={67} mobSize={47} />
        <Box flexDirection="column" alignSelf="center" theme="quote" className="block5_8">
          <Typography component="p" variant="textM" color="black_1" className="block5_8_text">
            {block5.quote1}
          </Typography>
          <Spacer size={7} />
          <Typography component="p" variant="textM" color="black_1" className="block5_8_text">
            {block5.quote2}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
