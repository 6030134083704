import {useMemo} from 'react';

import './Spacer.css';

export const Spacer = ({size, tabSize, mobSize}) => {
    const style = useMemo(() => {
        return {
            '--spacer-desktop-size': `${size * 2}px`,
            '--spacer-tablet-size': tabSize ? `${tabSize * 2}px` : undefined,
            '--spacer-mobile-size': mobSize ? `${mobSize * 2}px` : undefined,
        }
    }, [size, tabSize, mobSize])

    return (
        <div aria-hidden="true" className="spacer" style={style}>
            {' '}
        </div>
    );
};