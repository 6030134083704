import { useMemo } from "react";
import cn from "classnames";

import "./Box.css";

export const Box = ({
  children,
  theme,
  width,
  flex,
  flexDirection,
  justifyContent,
  alignItems,
  alignSelf,
  flexWrap,
  className,
}) => {
  const style = useMemo(() => {
    return {
      width: `${width}px`,
      flex: flex,
      flexDirection: flexDirection,
      justifyContent: justifyContent,
      alignItems: alignItems,
      alignSelf: alignSelf,
      flexWrap: flexWrap,
    };
  }, [
    width,
    flex,
    flexDirection,
    justifyContent,
    alignItems,
    alignSelf,
    flexWrap,
  ]);

  return (
    <div className={cn("box", `boxTheme_${theme}`, className)} style={style}>
      {children}
    </div>
  );
};
